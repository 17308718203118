import React from 'react';
import { WrapPageElementBrowserArgs } from 'gatsby';

import Layout from './src/components/Layout';
import CartProvider from './src/contexts/cartContext';
import ParamsProvider from './src/contexts/paramsContext';
import CommonProvider from './src/contexts/commonContext';
import I18NextProvider, { PageContext } from './src/contexts/i18nContext';

declare global {
  interface Window {
    gtag(...args: any): void;
    dataLayer?: any[];
  }
}

export const onRouteUpdate = ({ location }) => {
  console.log('FFF onRouteUpdate', location);

  const pagePath = location ? location.pathname : undefined;

  // setTimeout(() => {
  //   if (typeof window.gtag === 'function') {
  //     window.gtag('event', 'page_view', { page_path: pagePath });
  //     console.log('FFF send event ', window.gtag);
  //   }
  // }, 100);
};

export const wrapRootElement = ({ element }: WrapPageElementBrowserArgs<any, PageContext>) => {
  return (
    <CommonProvider>
      <CartProvider>
        <ParamsProvider>{element}</ParamsProvider>
      </CartProvider>
    </CommonProvider>
  );
};

export const wrapPageElement = ({ element, props }: any, localeProps: any) => (
  <I18NextProvider options={localeProps} {...props}>
    <Layout {...props}>{element}</Layout>
  </I18NextProvider>
);
