import { useEffect, useState } from 'react';

export type GEO = {
  city?: string;
  country?: {
    code?: string;
    name?: string;
  };
  subdivision?: {
    code?: string;
    name?: string;
  };
  latitude?: number;
  longitude?: number;
  timezone?: string;
};

const useGeo = () => {
  const [geo, setGeo] = useState<GEO | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      fetch('https://amanitamuscariastore.online/geolocation')
        .then((data) => data.json())
        .then(setGeo);
    }
  }, []);

  return geo;
};

export default useGeo;
